





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { ORegistrationFeeList: () => import('@/components/organisms/event/modules/registrationModule/o-registration-fee-list.vue')},

  setup(){

    return {}
  }
})
